<template>
  <the-header />
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap w-full mb-20">
        <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
          <h2
            class="sm:text-3xl text-2xl font-medium title-font mb-2 text-blue-700"
          >
            Мои услуги и цены
          </h2>
          <div class="h-1 w-20 bg-indigo-500 rounded"></div>
        </div>
        <p class="lg:w-1/2 w-full leading-relaxed md:hidden text-blue-700">
          Якубов Мирсултан Мирварисович <br />
          врач-отоларинголог высшей категории
        </p>
      </div>
      <div class="flex flex-wrap -m-4">
        <div class="xl:w-1/4 md:w-1/2 p-4" v-for="price in prices" :key="price">
          <div class="bg-gray-100 p-6 rounded-lg">
            <img
              class="h-48 rounded w-full object-cover object-center mb-6"
              :src="price.img"
              :alt="price.name"
            />
            <h3
              class="tracking-widest text-blue-700 text-base font-medium title-font"
            >
              {{ price.name }}
            </h3>
            <h2 class="text-lg text-blue-800 font-medium title-font mb-4">
              {{ price.sum }}
            </h2>
            <p class="leading-relaxed text-base">
              {{ price.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <the-footer />
</template>

<script>
import TheFooter from "../components/footer/TheFooter.vue";
import TheHeader from "../components/header/TheHeader.vue";
export default {
  components: { TheHeader, TheFooter },
  data() {
    return {
      prices: [
        {
          sum: "от " + "2.500.000",
          name: "Аденотомия",
          description:
            "Аденоидэктомия - это хирургическое удаление аденоида по причинам, включающим нарушение дыхания через нос, хронические инфекции или рецидивирующие боли в ушах",
          img: require("@/components/price/img_01.png"),

        },
        {
          sum: "от " + "3.500.000",
          name: "Аденотонзиллотомия",
          description:
            "Аденотонзиллотомия — это операция удаления глоточной миндалины с одновременным разрезом небных миндалин.",
          img: require("@/components/price/img_02.png"),
        },
        {
          sum: "от " + "5.000.000",
          name: "Септопла́стика",
          description:
            "Септопла́стика — хирургическая операция по устранению искривления носовой перегородки. В отличие от ринопластики, целью операции является не восстановление правильной формы носа, а улучшение носового дыхания.",
          img: require("@/components/price/img_03.png"),
        },
        {
          sum: "от " + "4.500.000",
          name: "Гайморотомия",
          description:
            "Гайморотомия — хирургическая операция вскрытия гайморовой пазухи с последующим удалением её патологического содержимого.",
          img: require("@/components/price/img_04.png"),
        },
        {
          sum: "от " + "4.500.000",
          name: "Вазотомия",
          description:
            "Вазотомия — хирургическая операция, приносящая стойкий положительный эффект в борьбе с проявлениями вазомоторного ринита. При вазотомии проводят рассечение сосудистых соединений между слизистой оболочкой и надкостницей носовых раковин.",
          img: require("@/components/price/img_05.png"),
        },
        {
          sum: "от " + "6.500.000",
          name: "Полипотомия",
          description:
            "Полипотомия – это операция по удалению новообразований слизистой оболочки носовой полости. Полипы могут образовываться как в полости носа, так и в носовых пазухах.",
          img: require("@/components/price/img_06.png"),
        },
        {
          sum: "от " + "2.500.000",
          name: "Репозиция костей носа",
          description:
            "Репозиция костей носа (или вправление носа) — процедура по установлению смещенных частей и отломков костей и хряща на нужное место.",
          img: require("@/components/price/img_07.png"),
        },
      ],
    };
  },
};
</script>

<style></style>
