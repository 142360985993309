<template>
  <div
    id="accordion-flush"
    data-accordion="collapse"
    data-active-classes="bg-white dark:bg-gray-900 text-blue-600 dark:text-white"
    data-inactive-classes="text-black dark:text-gray-400"
    class="bg-white rounded-lg p-4 mb-12"
  >
    <h2 id="accordion-flush-heading-1">
      <button
        type="button"
        class="flex items-center justify-between py-5 w-full font-medium text-left border-b border-gray-200 dark:border-gray-700 text-black dark:text-white rounded-t-xl"
        data-accordion-target="#accordion-flush-body-1"
        aria-expanded="false"
        aria-controls="accordion-flush-body-1"
      >
        <span> Якубов Мирсултан Мирварисович</span>
        <svg
          data-accordion-icon
          class="w-6 h-6 shrink-0 rotate-180"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </h2>
    <div
      id="accordion-flush-body-1"
      aria-labelledby="accordion-flush-heading-1"
    >
      <div class="py-5 border-b border-gray-200 ">
        <p class="mb-2 text-black font-medium">
          Якубов Мирсултан Мирварисович - врач оториноларинголог (ЛОР). <br />
          Опыт работы: 13 лет 1998-2005 гг окончил Ташкентский Педиатрический
          Медицинский Институт. Получил Диплом с квалификацией ВОП. Степень
          бакалавра.
          <br />
          2005-2008 гг окончил магистратуру в ЛОР отделении в Ташкентском
          Педиатрическом Медицинском Институте. Получил степень магистра по
          оториноларингологии. <br />
          С 2009 по 2013 гг работал ЛОР врачом Взрослой клинике Ташкентском
          Педиатрическом Медицинском Институте. <br />
          С 2013 года работает ассистентом кафедры оториноларингологии, детской
          оториноларингологии и стоматологии ТашПМИ. А также с декабря 2013 г.
          он работает как дежурный оториноларинголог в Санавиации РУз. Якубов
          М.М. является постоянным членом ассоциации оториноларингологов РУз.
          <br />
        </p>
      </div>
    </div>
    <h2 id="accordion-flush-heading-2">
      <button
        type="button"
        class="flex items-center justify-between py-5 w-full font-medium border-b border-gray-200 text-left text-black"
        data-accordion-target="#accordion-flush-body-2"
        aria-expanded="false"
        aria-controls="accordion-flush-body-2"
      >
        <span>Провожу операции по следующим болезням:</span>
        <svg
          data-accordion-icon
          class="w-6 h-6 shrink-0"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </h2>
    <div
      id="accordion-flush-body-2"
      class="hidden"
      aria-labelledby="accordion-flush-heading-2"
    >
      <div class="py-5 border-b border-gray-200">
        <p class="mb-2 text-black text-left">
          <b>Септопластика </b> — выпрямление носовой перегородки хирургическим
          путем;
          <br />
          <b>Тонзиллэктомия </b>
          — операция по удалению миндалин;<br />
          <b>Вазотомия </b>
          — радиоволновая вазотомия нижних носовых раковин;
          <br />
          <b> Полипы придаточных пазух носа; <br /></b>
          <b>Киста придаточных пазух носа; <br /></b>
          <b>Аллергический ринит; <br /></b>
          <b>Хронический тонзиллит.</b> <br>

          <router-link class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" to="/Experience">Все услуги</router-link>
        </p>
      </div>
    </div>
    <h2 id="accordion-flush-heading-3">
      <button
        type="button"
        class="flex items-center border-b border-gray-200 justify-between py-5 w-full font-medium text-left text-black"
        data-accordion-target="#accordion-flush-body-3"
        aria-expanded="false"
        aria-controls="accordion-flush-body-3"
      >
        <span>9 причин обратиться к оториноларингологу </span>
        <svg
          data-accordion-icon
          class="w-6 h-6 shrink-0"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </h2>
    <div
      id="accordion-flush-body-3"
      class="hidden"
      aria-labelledby="accordion-flush-heading-3"
    >
      <div class="py-5 border-b border-gray-200">
        <p class="mb-2 text-black font-semibold text-left">
          Затруднение носового дыхания; <br />
          Нарушение речи, гнусавость; <br />
          Нарушения слуха, боли в ушах и головные боли; <br />
          Периодический или постоянный насморк; <br />
          Повышенная утомляемость, снижение работоспособности; <br />

          Хронические заболевания дыхательных путей;<br />
          Хронические заболевания желудочно-кишечного тракта; <br />
          Частые инфекционно-воспалительные заболевания; <br />
          Частые простудные заболевания.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
