<template>
  <div class="bg-blue-50">
    <TheHeader />
    <Content />
    <TheFooter />
  </div>
</template>

<script>
import Content from "@/components/content/Content.vue";
import TheHeader from "@/components/header/TheHeader.vue";
import TheFooter from "@/components/footer/TheFooter.vue";

export default {
  components: { TheHeader, Content, TheFooter },
};
</script>

<style></style>
