<template>
<The-header />
  <photos />
  <the-footer />
</template>

<script>
import Photos from './Photos.vue'
import TheFooter from '../footer/TheFooter.vue'
import TheHeader from '../header/TheHeader.vue'
export default {
  components: { TheHeader, TheFooter, Photos },

}
</script>

<style>

</style>