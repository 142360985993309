<template>
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-20 md:py-24 mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        <h2
          class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"
        >
          Сертификаты
        </h2>
        <p class="lg:w-1/2 w-full leading-relaxed mx-auto text-gray-500">
          Якубов Мирсултан Мирварисович <br />
          врач-отоларинголог высшей категории
        </p>
      </div>
      <div class="flex flex-wrap -m-4">
        <div
          class="lg:w-1/3 sm:w-1/2 w-full p-4 mb-4 md:mb-16"
          v-for="(certificate, index) in certificates"
          :key="index"
        >
          <div class="flex relative mb-4">
            <img
              alt="gallery"
              class="absolute inset-0 w-full h-52 md:h-60 object-center"
              :src="certificate.src"
            />
            <div
              class="px-8 py-10 relative z-10 w-full bg-white opacity-0 hover:opacity-100"
            >
              <h2
                class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1"
              >
                Сертификаты
              </h2>
              <h2 class="title-font text-lg font-medium text-gray-900 mb-3">
                Якубов Мирсултан
              </h2>
              <p class="leading-relaxed">
                врач-отоларинголог высшей категории
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      certificates: [
        { src: require('@/components/certificates/IMG_0005.jpg') },
        { src: require('@/components/certificates/IMG_0006.jpg') },
        { src: require('@/components/certificates/IMG_0007.jpg') },
        { src: require('@/components/certificates/IMG_0008.jpg') },
        { src: require('@/components/certificates/IMG_0011.jpg') },
        { src: require('@/components/certificates/IMG_0012.jpg') },
        { src: require('@/components/certificates/IMG_0013.jpg') },
        { src: require('@/components/certificates/IMG_0014.jpg') },
        { src: require('@/components/certificates/IMG_0015.jpg') },
        { src: require('@/components/certificates/IMG_0016.jpg') },
        { src: require('@/components/certificates/IMG_0017.jpg') },
        { src: require('@/components/certificates/IMG_0018.jpg') },
        { src: require('@/components/certificates/IMG_0019.jpg') },
        { src: require('@/components/certificates/IMG_0020.jpg') },

      ],
    };
  },
};
</script>

<style></style>
