<template>
  <div class="container px-5 py-16 mx-auto flex flex-wrap">
    <div class="flex w-full mb-20 flex-wrap">
      <h1
        class="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4"
      >
        Якубов Мирсултан Мирварисович
      </h1>
      <p class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base sm:mt-2">
        Высокая квалификация и профессионализм врача гарантируют желаемый
        результат!
      </p>
    </div>
    <div class="flex flex-wrap md:-m-2 -m-5 justify-center">
      <div class="flex flex-wrap md:w-1/2 w-4/5">
        <div class="md:p-2 p-1 w-1/2">
          <img
            alt="gallery"
            class="w-full object-cover h-full object-center block"
            src="../gallery/gallery_02.png"
          />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img
            alt="gallery"
            class="w-full object-cover h-full object-center block"
            src="@/assets/Mirsultan_01.jpg"
          />
        </div>
        <div class="md:p-2 p-1 w-full">
          <img
            alt="gallery"
            class="w-full h-full object-cover object-center block"
            src="../gallery/gallery_01.png"
          />
        </div>
      </div>
      <div class="flex flex-wrap md:w-1/2 w-4/5">
        <div class="md:p-2 p-1 w-full">
          <img
            alt="gallery"
            class="w-full h-full object-cover object-center block"
            src="../gallery/gallery_03.png"
          />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img
            alt="gallery"
            class="w-full object-cover h-full object-center block"
            src="../gallery/gallery_05.png"
          />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img
            alt="gallery"
            class="w-full object-cover h-full object-center block"
            src="@/assets/Mirsultan_02.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
