<template>
  <div class="bg-blue-800 py-3.5 px-6 shadow">
    <div
      class="container mx-auto md:flex justify-between items-center font-serif"
    >
      <div>
        <router-link to="/" class="flex items-center">
          <span class="text-blue-600 font-semibold text-xl -ml-2 md:mr-1">
            <img
              src="@/assets/logos.png"
              class="w-16"
              alt="Лор-отоларинголог-Ташкент-Узбекистан"
            />
          </span>
          <h2 class="text-2xl font-sans font-bold hidden sm:block text-white">
            Lor Clinic
          </h2>
        </router-link>
      </div>
      <span
        @click="MenuOpen()"
        class="absolute text-white md:hidden right-6 top-7 cursor-pointer text-3xl duration-200"
      >
        <!-- :class="[open ? 'menu-alt-right' : 'window-close']" -->
        <i :class="[open ? 'bx bxs-x-circle' : 'bx bx-menu-alt-right']"></i>
      </span>
      <ul
        class="md:flex md:items-center md:px-0 px-3 md:pb-0 pb-10 md:static absolute bg-white bg-blue-800 w-full md:w-auto w-75 top-17 left-0 duration-700"
        :class="[open ? 'static' : 'hidden']"
      >
        <li
          class="md:mx-4 md:my-0 my-5 flex justify-center"
          v-for="link in links"
          :key="link"
        >
          <router-link
            :to="link.href"
            class="text-white font-sans hover:text-gray-800 md:text-xl font-bold transform hover:scale-105 duration-200"
            >{{ link.text }}</router-link
          >
        </li>
        <li class="flex justify-center">
          <Button>
            <a href="tel:+998998331982"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="fill: rgba(255, 255, 255, 1); transform: ; msfilter: "
              >
                <path
                  d="M20 10.999h2C22 5.869 18.127 2 12.99 2v2C17.052 4 20 6.943 20 10.999z"
                ></path>
                <path
                  d="M13 8c2.103 0 3 .897 3 3h2c0-3.225-1.775-5-5-5v2zm3.422 5.443a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a1 1 0 0 0-.086-1.391l-4.064-3.696z"
                ></path>
              </svg>
            </a>
          </Button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import { ref } from "@vue/reactivity";
export default {
  components: { Button },
  setup() {
    let open = ref(false);

    let links = [
      {
        text: "Главная",
        href: "/",
      },
      {
        text: "Услуги",
        href: "/Experience",
      },
      {
        text: "Сертификаты",
        href: "/Certificate",
      },
      {
        text: "Галерея",
        href: "/Gallery",
      },
      {
        text: "Контакты",
        href: "/Contact",
      },
    ];
    function MenuOpen() {
      open.value = !open.value;
    }
    return {
      links,
      open,
      MenuOpen,
    };
  },
};
</script>

<style></style>
