<template>
  <button
    class="bg-blue-600 hover:bg-blue-700 duration-300 font-sm text-white rounded py-1.5 px-4 font-medium"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style></style>




