<template>
  <footer class="text-gray-600 body-font bg-white">
    <div
      class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col"
    >
      <a
        class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
      >
        <img
          src="@/assets/logos.png"
          class="w-16"
          alt="Лор-отоларинголог-Ташкент-Узбекистан"
        />
        <span class="ml-3 text-xl hidden sm:block">Lor Clinic</span>
      </a>
      <p
        class="text-sm text-blue-700 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4"
      >
        © 2022 JBNMedia —
        <a
          href="https://t.me/jbnmedia"
          class="text-red-800 ml-1 cursor-pointer font-semibold"
          rel="nofollow"
          target="_blank"
          >@jbnmedia</a
        >
      </p>
      <span
        class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start"
      >
        <a href="https://www.facebook.com/mirsultan.yakubov" rel="nofollow" target="blanc" class="text-blue-700 cursor-pointer">
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-7 h-7"
            viewBox="0 0 24 24"
          >
            <path
              d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
            ></path>
          </svg>
        </a>
        <a href="https://t.me/lorclinicuz" rel="nofollow" class="ml-3 text-blue-500 cursor-pointer" target="blanc">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
            ></path>
          </svg>
        </a>
        <a href="https://www.instagram.com/lorclinic.uz" rel="nofollow" class="ml-3 text-red-700 cursor-pointer" target="blanc">
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-7 h-7"
            viewBox="0 0 24 24"
          >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path
              d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
            ></path>
          </svg>
        </a>
        <a href="https://www.youtube.com/channel/UCzcAS1wYymgSVzYVib776nQ/featured" class="ml-3 text-red-700 cursor-pointer" rel="nofollow" target="blanc">
          <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            rel="nofollow"
            width="28"
            height="28"
            viewBox="0 0 24 24"
          >
            <path
              d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"
            ></path>
          </svg>
        </a>
      </span>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
