<template>
  <div class="container-full bg-blue-800">
    <div class="container px-5 py-6 md:py-18 mx-auto">
      <div class="flex flex-wrap -m-4 text-center text-white font-bold">
        <div class="p-4 sm:w-1/4 w-1/2">
          <h2 class="title-font font-medium sm:text-4xl text-3xl">3.9K +</h2>
          <p class="leading-relaxed">Операций</p>
        </div>
        <div class="p-4 sm:w-1/4 w-1/2">
          <h2 class="title-font font-medium sm:text-4xl text-3xl">592</h2>
          <p class="leading-relaxed">Операций за год</p>
        </div>
        <div class="p-4 sm:w-1/4 w-1/2">
          <h2 class="title-font font-medium sm:text-4xl text-3xl">15 лет</h2>
          <p class="leading-relaxed">Стаж</p>
        </div>
        <div class="p-4 sm:w-1/4 w-1/2">
          <h2 class="title-font font-medium sm:text-4xl text-3xl">18+</h2>
          <p class="leading-relaxed">Операций зарубежом</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
