<template>

  <div>

  <router-view />
  </div>
</template>
<script>

export default {

};
</script>

<style
src="@/assets/tailwind.css"
></style>
