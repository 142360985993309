<template>
  <div class="container-full bg-white pb-4">
    <div class="container mx-auto flex items-center justify-center flex-col">
      <img
        class="lg:w-full md:w-2/3 md:block hidden mb-10 object-cover object-center rounded"
        alt="MirsultonYoqubov оториноларинголог"
        src="@/assets/Mirsultan_03.jpg"
      />
      <img
        class="md:hidden w-full h-72 mb-10 object-cover object-center rounded"
        alt="MirsultanYakubov Lor Лор"
        src="@/assets/Mirsultan_03.jpg"
      />

      <div class="text-center lg:w-2/3 w-full">
        <h1
          class="title-font sm:text-4xl text-3xl mb-6 font-medium text-blue-700"
        >
          Якубов Мирсултан Мирварисович
        </h1>
        <Accordion />

        <div>
          <div
            class="max-w-sm bg-blue-800 rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mx-auto"
          >
            <div class="flex flex-col items-center pt-10 pb-10">
              <img
                class="mb-3 w-24 h-24 rounded-full shadow-lg"
                src="../content/img/Mirsultan.jpg"
                alt="Bonnie image"
              />
              <h3 class="mb-1 text-xl font-bold text-white dark:text-white">
                Якубов Мирсултан
              </h3>
              <span class="text-sm text-white font-semibold dark:text-gray-400"
                >Оториноларинголог</span
              >
              <div class="flex mt-4 space-x-3 lg:mt-6">
                <a
                  href="tel:+998 99 833 19 82"
                  class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >Позвонить</a
                >
                <a
                  href="https://t.me/lorclinicuz"
                  target="blank"
                  class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-blue-800 bg-white rounded-lg border focus:ring-4 focus:ring-blue-300 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-800"
                  >Написать</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Photos />
  <Lore />
  <Otzivi />
  <Numbers />
</template>

<script>
import Photos from "../gallery/Photos.vue";
import Accordion from "./Accordion.vue";
import Lore from "./Lore.vue";
import Numbers from "./Numbers.vue";
import Otzivi from "./Otzivi.vue";
export default {
  components: { Lore, Otzivi, Numbers, Accordion, Photos },
};
</script>

<style></style>
