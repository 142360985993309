<template>
  <div class="container-full bg-white">
    <div class="container px-5 py-4 md:py-24 mx-auto bg-white">
      <div class="flex flex-col text-center w-full mb-2 sm:mb-8">
        <h2
          class="sm:text-3xl text-2xl font-medium title-font mb-4 text-blue-700"
        >
          Отзывы
        </h2>
      </div>
      <div class="flex flex-wrap -m-4">
        <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
          <div class="h-full text-center">
            <img
              alt="testimonial"
              class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
              src="https://dummyimage.com/302x302"
            />
            <p class="leading-relaxed">
              Выражаю искреннюю благодарность врачу Якубову Мирсултану,
              грамотному и квалифицированному специалисту своего дела. Благодаря
              своевременным рекомендациям и правильно подобранным методам
              лечения выздоровел, болезнь отступила, чувствую себя хорошо.
              Спасибо за помощь
            </p>
            <span
              class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"
            ></span>
            <h2
              class="text-gray-900 font-medium title-font tracking-wider text-sm"
            >
              Александр Молотков
            </h2>
            <p class="text-gray-500">Пациент</p>
          </div>
        </div>
        <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
          <div class="h-full text-center">
            <img
              alt="testimonial"
              class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
              src="https://dummyimage.com/300x300"
            />
            <p class="leading-relaxed">
              Классный врач. Ничего лишнего не назначает. Работает на результат.
              Очень грамотный и внимательный специалист. Побольше бы таких.
              Очень вежливый рекомендую!.
            </p>
            <span
              class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"
            ></span>
            <h2
              class="text-gray-900 font-medium title-font tracking-wider text-sm"
            >
              Наргиза Ахмедова
            </h2>
            <p class="text-gray-500">Пациент</p>
          </div>
        </div>
        <div class="lg:w-1/3 lg:mb-0 p-4">
          <div class="h-full text-center">
            <img
              alt="testimonial"
              class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
              src="https://dummyimage.com/305x305"
            />
            <p class="leading-relaxed">
              Уважаемый Мирсултан Мирварисович! Низкий Вам поклон-вернули слух.
              В городе прошла 5 поликлиник, все ссылались на "возрост" и т.д.
              и.т.п Я смерилась с этим. А Вы четко и быстро разобрались и
              сделали то, что я не ожидала. Оказалось ухо здоровое. Спасибо Вам
              БУДЬТЕ ЗДОРОВЫ.
            </p>
            <span
              class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"
            ></span>
            <h2
              class="text-gray-900 font-medium title-font tracking-wider text-sm"
            >
              Надира Абдураимова
            </h2>
            <p class="text-gray-500">Пациент</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
