<template>
  <The-header class="z-50" />
  <div>
    <div class="flex flex-col text-center w-full mb-2 mt-10 md:mt-20 p-2">
      <h2
        class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"
      >
        Контакты
      </h2>
      <p
        class="sm:text-xl text-lg font-medium title-font mt-4 mb-0 text-indigo-500"
      >
        <Button>
          <a class="flex items-center" href="tel:+998998331982"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(255, 255, 255, 1); transform: ; msfilter: "
            >
              <path
                d="M20 10.999h2C22 5.869 18.127 2 12.99 2v2C17.052 4 20 6.943 20 10.999z"
              ></path>
              <path
                d="M13 8c2.103 0 3 .897 3 3h2c0-3.225-1.775-5-5-5v2zm3.422 5.443a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a1 1 0 0 0-.086-1.391l-4.064-3.696z"
              ></path>
            </svg>
            +998998331982
          </a>
        </Button>
      </p>
      <p
        class="sm:text-xl text-lg font-medium title-font mt-4 mb-0 text-indigo-500"
      >
        Рабочие дни | Первая смена | Клиника Happy Life
      </p>
      <div class="flex flex-wrap lg:w-4/5 sm:mx-auto mt-2 sm:mb-2 -mx-2">
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">8:00 - 13:00</span>
              Понедельник
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">8:00 - 13:00</span> Вторник
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">8:00 - 13:00</span> Среда
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">8:00 - 13:00</span> Четверг
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">8:00 - 13:00</span> Пятница
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">8:00 - 13:00</span> Суббота
            </span>
          </div>
        </div>
      </div>
    </div>
    <section class="text-gray-600 body-font">
      <div class="container py-10 md:py-24 pb-10 mx-auto flex justify-center">
        <div
          class="lg:w-2/3 md:w-1/2 w-11/12 h-48 md:h-96 bg-gray-300 rounded-lg overflow-hidden p-10 relative"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11975.430870275599!2d69.3137136!3d41.3771759!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa9dbf1c94d169c3a!2sHappy%20life!5e0!3m2!1sru!2s!4v1645466612941!5m2!1sru!2s"
            width="100%"
            height="100%"
            class="absolute inset-0"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            lading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  </div>
  <div>
    <!-------------------------------- section 2 --------------------------------------------->

    <div class="flex flex-col text-center w-full mb-2 mt-1 md:mt-4 p-2">
      <p
        class="sm:text-xl text-lg font-medium title-font mt-1 mb-0 text-indigo-500"
      >
        Рабочие дни | Вторая смена | Клиника HAYAT MEDICAL CENTRE
      </p>
      <div class="flex flex-wrap lg:w-4/5 sm:mx-auto mt-2 sm:mb-2 -mx-2">
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">14:00 - 18:00</span>
              Понедельник
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">14:00 - 18:00</span>
              Вторник
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">14:00 - 18:00</span> Среда
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">14:00 - 18:00</span>
              Четверг
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">14:00 - 18:00</span>
              Пятница
            </span>
          </div>
        </div>
        <div class="p-2 sm:w-1/2 w-full">
          <div class="bg-gray-100 rounded flex p-4 h-full items-center">
            <svg
              class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(104, 117, 245, 1); transform: ; msfilter: "
            >
              <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path d="M13 7h-2v6h6v-2h-4z"></path>
            </svg>
            <span class="title-font font-medium"
              ><span class="text-sm text-indigo-500">14:00 - 18:00</span>
              Суббота
            </span>
          </div>
        </div>
      </div>
    </div>
    <section class="text-gray-600 body-font">
      <div class="container py-10 md:py-24 pb-10 mx-auto flex justify-center">
        <div
          class="lg:w-2/3 md:w-1/2 w-11/12 h-48 md:h-96 bg-gray-300 rounded-lg overflow-hidden p-10 relative"
        >
          <iframe
            width="100%"
            height="100%"
            class="absolute inset-0"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            lading="lazy"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2994.3219406290823!2d69.33395641658818!3d41.36708965254737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8c2edec291de12b7!2shayat%20medical%20centre!5e0!3m2!1sru!2s!4v1662309836038!5m2!1sru!2s"
          ></iframe>
        </div>
      </div>
    </section>
  </div>
  <The-footer />
</template>

<script>
import Button from "../components/header/Button.vue";
import TheFooter from "../components/footer/TheFooter.vue";
import TheHeader from "../components/header/TheHeader.vue";
export default {
  components: { TheFooter, TheHeader, Button },
};
</script>

<style></style>
