<template>
<div class="bg-blue-100">

  <The-header />

  <certificate-slider/>
  <the-footer />
</div>
</template>

<script>
import CertificateSlider from "@/components/certificates/CertificateSlider.vue";

import TheFooter from "../components/footer/TheFooter.vue";
import TheHeader from "../components/header/TheHeader.vue";
export default {
  components: { TheHeader, TheFooter, CertificateSlider },
};
</script>

<style></style>
